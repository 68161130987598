<template>
  <div class="home">
    <div
      v-if="isNoXCX"
      class="common-back"
      @click="backAction"
    >
      <img
        src="https://artimg2.artart.cn/image/20220428/c2e0865b1ace1cb7c56e7427381c234e.png"
        alt=""
      >
    </div>
    <div class="top-img">
      <div
        class="item-flex-center"
        style="width: 100%;"
      >
        <div :class="is_follow_official_account === false && qrcode_url ? 'd3-wrap-no' : 'd3-wrap'">
          <div class="rotatef">
            <van-image
              class="img"
              :src="nftData.product.image + (nftData.product.image.toLowerCase().indexOf('.gif') === -1 ? '!m640' : '')"
              fit="contain"
            ></van-image>
          </div>
        </div>
      </div>
      <div class="name">{{ nftData.product.store_name }}</div>
      <div class="tags item-flex-center flex-wrap">
        <div
          class="tag"
          v-for="(m, i) in nftData.product.LabelNames"
          :key="`tag${i}`"
        >{{ m }}</div>
        <div class="num">限量{{ nftData.issuer_num }}份</div>
      </div>
    </div>
    <div
      v-if="nftData.message"
      class="img-desc common-bor"
    >
      <div class="common-title">作品故事</div>
      <div class="img-list">
        <div v-html="nftData.message"></div>
      </div>
    </div>
    <div class="img-desc common-bor mt32">
      <div
        class="common-title"
        style="padding: 0;"
      >作品信息</div>
      <div class="work-list">
        <div class="item flex">
          <div class="l">创作者</div>
          <div class="r">{{ nftData.author }}</div>
        </div>
        <div class="item flex">
          <div class="l">发行方</div>
          <div class="r">{{ nftData.issuer }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="agree"
      class="img-desc common-bor mt32"
    >
      <div
        class="common-title"
        style="padding: 0;"
      >{{ agree.title }}</div>
      <div class="detail">
        <div v-html="agree.message"></div>
      </div>
    </div>
    <div class="btn-bottom flex align-items-c justify-b">
      <div class="l">￥<span>{{ formatPriceNumber(nftData.product.price, 2) }}</span></div>
      <div class="r flex">
        <div
          v-if="nftData.product.stock <= 0"
          class="is-sell-out btn"
        >已售罄</div>
        <template v-else-if="nftData.goTime > nftData.djsPD">
          <!-- <div class="qd">订阅提醒</div> -->
          <div class="qd ml10">{{ nftData.dayArr[1] }}.{{ nftData.dayArr[2] }} {{ nftData.timeArr[0] }}:{{ nftData.timeArr[1] }} 开售</div>
        </template>
        <template v-else-if="nftData.goTime > 0">
          <!-- <div class="qd">订阅提醒</div> -->
          <van-count-down
            :time="nftData.goTime * 1000"
            @finish="isFinishAction"
          >
            <template #default="timeData">
              <div class="qd ml10">即将开售 {{ formatDateNumber(timeData.hours) }}:{{ formatDateNumber(timeData.minutes) }}:{{ formatDateNumber(timeData.seconds) }}</div>
            </template>
          </van-count-down>
        </template>
        <div
          v-else
          class="btn"
          @click="showPayType"
        >购买</div>
      </div>
    </div>
    <div
      class="change-pay-type item-flex-center"
      v-if="showChangePayType"
      @click="showChangePayType = false;"
    >
      <div
        class="pay-type-content"
        @click.stop="kong"
      >
        <div class="pay-type-title">支付<img
            class="close"
            src="https://artimg2.artart.cn/image/20220501/a9f6a5d91c404291dcfa529524148910.png"
            @click="showChangePayType = false;"
          ></div>
        <div class="pay-type-c">
          <div
            class="item flex align-items-c"
            @click="changeType('wx')"
          >
            <div class="icon">
              <img
                src="https://artimg2.artart.cn/image/20220501/9923e18b26637c0c24003225784854d2.png"
                alt=""
              >
            </div>
            <div class="name">微信支付</div>
            <div class="chiose">
              <img
                v-if="payType == 'wx'"
                src="https://artimg2.artart.cn/image/20220501/b4765cb54b83166da4eee287a876f9cc.png"
                alt=""
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220501/7a96feee530103d0dcd39808f7e189db.png"
                alt=""
              >
            </div>
          </div>
          <div
            class="item flex align-items-c"
            @click="changeType('yue')"
          >
            <div class="icon">
              <img
                style="height: 0.666667rem"
                src="https://artimg2.artart.cn/image/20220501/7e9f4c14a112804cbee31dafaf64be77.png"
                alt=""
              >
            </div>
            <div class="name">钱包余额 <span class="ft12 color-gray ml5">可用 {{ formatPriceNumber(account.balance, 2) }}</span></div>
            <div class="chiose">
              <img
                v-if="payType == 'yue'"
                src="https://artimg2.artart.cn/image/20220501/b4765cb54b83166da4eee287a876f9cc.png"
                alt=""
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220501/7a96feee530103d0dcd39808f7e189db.png"
                alt=""
              >
            </div>
          </div>
          <div
            v-if="entrust_account.balance"
            class="item flex align-items-c"
            @click="changeType('wtfyue')"
          >
            <div class="icon">
              <img
                style="height: 0.666667rem"
                src="https://artimg2.artart.cn/image/20220501/e1db4b3343e9e4d7849d8ed4e17567bd.png"
                alt=""
              >
            </div>
            <div class="name">委托方余额 <span class="ft12 color-gray ml5">可用 {{ formatPriceNumber(entrust_account.balance, 2) }}</span></div>
            <div class="chiose">
              <img
                v-if="payType == 'wtfyue'"
                src="https://artimg2.artart.cn/image/20220501/b4765cb54b83166da4eee287a876f9cc.png"
                alt=""
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220501/7a96feee530103d0dcd39808f7e189db.png"
                alt=""
              >
            </div>
          </div>
        </div>
        <div class="pay-type-btn">
          <div
            class="btn"
            @click="goPayAction"
          >立即支付</div>
        </div>
      </div>
    </div>
    <FollowQrcodeUrl
      :is-show="is_follow_official_account === false && qrcode_url"
    ></FollowQrcodeUrl>
  </div>
</template>

<script>
import { Image as VanImage, Toast, CountDown, Dialog } from "vant";
import { usePageVisibility } from '@vant/use';
import { ref, onBeforeMount, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getAgreement } from "@/api/system";
import { nftCollectionInfo, createNftApi } from "@/api/nft";
import { shareConfig } from "@/utils/share";
import {
  formatDateNumber,
  formatPriceNumber,
  payAction,
  returnWxPayType,
  isWeiXin,
} from "@/utils/util";
import { useStore } from "vuex";
import FollowQrcodeUrl from "@/components/FollowQrcodeUrl";

export default {
  components: {
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown,
    FollowQrcodeUrl
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const id = ref("");
    const nftData = ref("");
    const agree = ref("");
    const pageVisibility = usePageVisibility();

    watch(pageVisibility, (value) => {
      if (value == 'visible' && store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }
    });
    onBeforeMount(() => {
      id.value = route.query.id;
      if (!id.value) {
        Toast("出现错误，请返回重试");
        router.back();
        return false;
      }

      if (store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }

      const nData = localStorage.getItem("nftDetail");
      if (nData) {
        const aD = JSON.parse(nData);
        if (aD.product_id == id.value) {
          nftData.value = aD;
        }
      }

      loadDetail();

      getAgreement({ type: 7 }).then((res) => {
        agree.value = res.data.info;
      });

      if (!nft_info.value.status) {
        store.dispatch("user/getNftInfo");
      }
    });

    const nft_info = computed(() => {
      return store.getters.nft_info;
    });

    const loadDetail = () => {
      nftCollectionInfo(id.value)
        .then((res) => {
          const sT = res.data.start_time.split(" ");
          res.data.dayArr = sT[0].split("-");
          res.data.timeArr = sT[1].split(":");

          const startD = new Date();
          startD.setFullYear(
            Number(res.data.dayArr[0]),
            Number(res.data.dayArr[1]) - 1,
            Number(res.data.dayArr[2])
          );
          startD.setHours(
            Number(res.data.timeArr[0]),
            Number(res.data.timeArr[1]),
            Number(res.data.timeArr[2])
          );

          const sTime = Date.parse(startD) / 1000;
          res.data.goTime = sTime - res.server_time;
          const nowTime = new Date();
          nowTime.setHours(0, 0, 0);
          res.data.djsPD =
            86400 - (res.server_time - Date.parse(nowTime) / 1000);

          nftData.value = res.data;

          shareConfig(
            res.data.product.store_name,
            res.data.product.store_info,
            res.data.product.image,
            "",
            "",
            location.href
          );
        })
        .catch((message) => {
          Toast(message);
          router.back();
        });
    };

    // 余额
    const account = computed(() => {
      return store.getters.account;
    });
    // 余额
    const entrust_account = computed(() => {
      return store.getters.entrust_account;
    });

    const isFinishAction = () => {
      loadDetail();
    };

    const showChangePayType = ref(false);
    const payType = ref("wx");

    const showPayType = () => {
      if (nft_info.value.status == 0) {
        router.push("/users/register_nft");
      } else {
        const isWx = isWeiXin();
        if (isWx) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.navigateTo({
                url: "/pages/nftCollection/payment?id=" + id.value,
              });
            } else {
              router.push("/pages/nftCollection/payment?id=" + id.value);
            }
          });
        } else {
          router.push("/pages/nftCollection/payment?id=" + id.value);
        }
      }
      // showChangePayType.value = true;
    };

    const changeType = (val) => {
      if (val === "yue") {
        if (account.value.balance < nftData.value.product.price) {
          return false;
        }
      }
      if (val === "wtfyue") {
        if (entrust_account.value.balance < nftData.value.product.price) {
          return false;
        }
      }
      payType.value = val;
    };

    let payLock = false;

    const goPayAction = () => {
      // createNftApi
      const data = {
        product_id: nftData.value.product_id,
        pay_type: "wechat_mini",
        use_wallet_price: 0,
        use_entrust_wallet_price: 0,
      };
      if (
        payType.value === "yue" &&
        account.value.balance >= nftData.value.product.price
      ) {
        data.use_wallet_price = Number(nftData.value.product.price);
      }
      if (
        payType.value === "wtfyue" &&
        entrust_account.value.balance >= nftData.value.product.price
      ) {
        data.use_entrust_wallet_price = Number(nftData.value.product.price);
      }

      if (payLock) return false;
      payLock = true;

      createNftApi(data)
        .then((res) => {
          // console.log(res.data.data.pay_result.appId)
          showChangePayType.value = false;
          if (res.data.status == 1) {
            payLock = false;
            Toast("支付成功！");
            router.push("/users/my_nft");
          } else {
            payAction(
              res.data.result.config,
              () => {
                Toast("支付成功");
                payLock = false;
                router.push("/users/my_nft");
              },
              () => {
                payLock = false;
              },
              () => {
                payLock = false;
              }
            );
          }
        })
        .catch((data) => {
          showChangePayType.value = false;
          if (data.message === "未开通数字藏品账户") {
            router.push("/users/register_nft");
          } else {
            Toast(data.message);
          }
          payLock = false;
        });
    };

    const kong = () => {};

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    const is_follow_official_account = computed(() => {
      return store.getters.is_follow_official_account;
    });
    const qrcode_url = computed(() => {
      return store.getters.qrcode_url;
    });

    return {
      nftData,
      agree,
      formatDateNumber,
      showChangePayType,
      payType,
      account,
      entrust_account,
      formatPriceNumber,
      isNoXCX: returnWxPayType() == "wechat_h5",
      is_follow_official_account,
      qrcode_url,

      isFinishAction,
      showPayType,
      changeType,
      goPayAction,
      kong,
      backAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #1e1e1e;
  padding-bottom: 198px;
  max-height: 99999px;
}
.top-img {
  min-height: 976px;
  background: url(https://artimg2.artart.cn/image/20220428/8a0af909d33aa4949e649a552ea8af06.png)
    no-repeat;
  background-size: 750px 1028px;
  padding-top: 180px;
  box-sizing: border-box;
  padding-bottom: 30px;

  .gif-img {
    margin-left: 195px;
    position: relative;
    width: 360px;
    height: 360px;

    .img {
      width: 360px;
      height: 360px;
      display: block;
    }
  }

  .d3-wrap {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    width: 430px;
    height: 430px;
    perspective: 1200px;
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(0deg);
    transform-origin: 50% 50%;
    .rotatef {
      position: absolute;
      backface-visibility: hidden;
      width: 430px;
      height: 430px;
      animation: rotatef 10s infinite;

      .img {
        width: 430px;
        height: 430px;
        border-style: groove;
        border-width: 16px;
        border-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        box-sizing: border-box;
        box-shadow: 0px 40px 128px 0px rgb(0 0 0 / 20%);
      }
    }
  }

  .d3-wrap-no{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    width: 430px;
    height: 430px;
    perspective: 1200px;
    transform-style: preserve-3d;
    transform: rotateX(0deg) rotateY(0deg);
    transform-origin: 50% 50%;
    .rotatef {
      position: absolute;
      backface-visibility: hidden;
      width: 430px;
      height: 430px;
      // animation: rotatef 10s infinite;

      .img {
        width: 430px;
        height: 430px;
        border-style: groove;
        border-width: 16px;
        border-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        box-sizing: border-box;
        box-shadow: 0px 40px 128px 0px rgb(0 0 0 / 20%);
      }
    }
  }

  .name {
    margin-top: 200px;
    width: 520px;
    margin-left: 115px;
    font-size: 36px;
    font-weight: 500;
    color: #ffffff;
    line-height: 52px;
    text-align: center;
  }

  .tags {
    padding: 6px 32px 0 32px;
    color: #00eeff;
    font-size: 24px;

    .tag {
      margin-top: 10px;
      line-height: 36px;
      height: 36px;
      border-radius: 2px;
      margin-right: 10px;
      padding: 0 8px;
      font-size: 22px;
      background: #5f5f5f;
      color: #fff;

      &:first-child {
        background: #00eeff;
        color: #000;
      }
    }
    .num {
      margin-top: 10px;
    }
  }
}
.common-bor {
  width: 686px;
  background: #333333;
  border-radius: 16px;
  border: 2px solid #444444;
  margin-left: 32px;
  box-sizing: border-box;
  padding: 32px;

  .common-title {
    padding: 0 16px;
    font-size: 32px;
    color: #fff;
    font-weight: 500;
  }

  .img-list {
    margin-top: 32px;
    font-size: 26px;
    font-weight: 400;
    color: #999999;

    img {
      display: block;
      max-width: 100%;
    }
  }

  .work-list {
    margin-top: 14px;
    color: #fff;
    line-height: 48px;
    .l {
      color: #999;
      margin-right: 34px;
    }
    .r {
      flex: 1;
    }
  }

  .detail {
    margin-top: 20px;
    font-size: 26px;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
    max-height: 99999px;
    white-space: pre-wrap;
  }
}

.btn-bottom {
  height: 116px;
  background: #000;
  position: fixed;
  padding-bottom: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .l {
    color: #e6cb95;
    font-size: 24px;
    margin-left: 48px;

    span {
      font-size: 48px;
      font-family: NUM;
    }
  }

  .r {
    margin-right: 32px;
    .qd {
      height: 48px;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 24px;
      border: 2px solid rgba(255, 255, 255, 0.2);
      color: #00eeff;
      font-size: 24px;
      line-height: 48px;
      padding: 0 18px;
    }

    .btn {
      width: 200px;
      height: 76px;
      border-radius: 38px;
      line-height: 76px;
      text-align: center;
      font-weight: 500;
      font-size: 32px;
      background: #00eeff;
      border: 2px solid #00eeff;
      color: #000;

      &.is-sell-out {
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid rgba(255, 255, 255, 0.1);
        color: #666666;
      }
    }
  }
}
@keyframes rotatef {
  0% {
    transform: rotateY(-18deg);
  }
  50% {
    transform: rotateY(18deg);
  }
  100% {
    transform: rotateY(-18deg);
  }
}
.mt32 {
  margin-top: 32px;
}

.change-pay-type {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);

  .pay-type-content {
    background: #2d2d2d;
    width: 560px;
    border-radius: 32px;
    padding-bottom: 60px;

    .pay-type-title {
      padding-top: 42px;
      text-align: center;
      font-size: 36px;
      font-weight: 500;
      color: #fff;
      position: relative;

      img {
        width: 48px;
        height: 50px;
        display: block;
        top: 42px;
        right: 40px;
        position: absolute;
      }
    }

    .pay-type-c {
      margin-top: 4px;

      .item {
        padding: 44px 48px 0 48px;

        .name {
          flex: 1;
          margin: 0 16px;
          font-size: 28px;
          color: #fff;
        }

        .icon,
        .chiose {
          img {
            width: 48px;
            height: 48px;
            display: block;
          }
        }
      }
    }

    .pay-type-btn {
      margin-top: 72px;
      margin-left: 130px;

      .btn {
        width: 300px;
        height: 96px;
        background: #00eeff;
        border-radius: 48px;
        line-height: 96px;
        text-align: center;
        font-size: 32px;
        color: #000;
        font-weight: 500;
      }
    }
  }
}
</style>
